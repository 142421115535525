import { GetServerDataReturn, navigate } from 'gatsby';
import React, { useEffect } from 'react';

import BlogArticles from '../components/BlogPage/components/BlogArticles';
import Carousel from '../components/carousel';
import Footer from '../components/footer';
import Layout from '../components/layout';
import PageSection from '../components/PageSection';
import MainBlock from '../components/self-driving-cars-main';
import { GLOBAL_QUERY } from '../constants';


// eslint-disable-next-line import/order
import 'swiper/swiper-bundle.css';

import { TPageProp } from '../types';
import { IGlobalServerRuntimeData } from '../types/strapi/global';
import { ISelfDrivingCar } from '../types/strapi/selfDrivingCar';

import { getPosts } from './news/[code]';

const query = `
  query getSelfDrivingCar($locale: String) {
    ${GLOBAL_QUERY}
    selfDrivingCar(locale: $locale) {
      isHidden
      seo {
        metaTitle
        metaDescription
      }
      id
      double_block {
        header
        id
        text
        background {
          url
        }
        mobileBackground {
          url
        }
      }
      story_cards {
        header
        id
        text
        image {
          url
        }
        subText
        subTextSecond
      }
      slider {
        header
        header_position
        text
        text_position
        slider_items {
            url
        }
      }
      tags {
        id
        value
        text
      }
    }
  }
`;

type TProp = TPageProp<IServerRuntimeData>;

const SelfDrivingCar: React.FC<TProp> = ({ serverData }) => {
    const { seo, double_block, story_cards, slider, posts } = serverData.selfDrivingCar;

    const isHiddenPage = true;

    useEffect(() => {
        if(isHiddenPage) {
            navigate('/');
        }
    }, []);

    if(isHiddenPage) {
        return null;
    }

    return (
        <Layout
            seo={seo}
            navPanel={serverData.navPanel}
            global={serverData.global}
            respondForm={serverData.respondForm}
        >
            <MainBlock data={double_block} storyCards={story_cards} />
            <Carousel data={slider} />
            {posts && posts.length > 0 && (
                <PageSection>
                    <BlogArticles items={posts} carousel={true} head={true} headText="Новости по теме" headBolder={true} />
                </PageSection>
            )}

            <Footer data={serverData.footer} />
        </Layout>
    );
};

export default SelfDrivingCar;

interface IServerRuntimeData extends IGlobalServerRuntimeData {
    selfDrivingCar: ISelfDrivingCar
}


export async function getServerData(): Promise<GetServerDataReturn<IServerRuntimeData>> {
    try {
        const variables = JSON.stringify({
            locale: process.env.GATSBY_LOCALE_CODE
        });
        const response = await fetch(
            `${process.env.GATSBY_API_URL}/graphql?query=${query}&variables=${variables}`
        );

        const data: { data: IServerRuntimeData } = await response.json();

        if(data.data.selfDrivingCar.isHidden) {
            return {
                status: 404
            };
        }

        return {
            props: {
                selfDrivingCar: {
                    ...data.data.selfDrivingCar,
                    posts: await getPosts(-1, data.data.selfDrivingCar.tags.map((tag) => tag.value))
                },
                navPanel   : data.data.navPanel,
                global     : data.data.global,
                respondForm: data.data.respondForm,
                footer     : data.data.footer
            }
        };
    } catch(error) {
        return {
            status: 500
        };
    }
}

