import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import Swiper from 'swiper';

import { useClassnames } from '../../hooks/use-classnames';
import useFormattedText from '../../hooks/use-formatted-text';
import CursorLeft from '../../images/cursor-left.inline.svg';
import CursorRight from '../../images/cursor-right.inline.svg';
import { ILocalFile, IUrl } from '../../types';
import { THeaderPosition } from '../../types/strapi/common';
import { toUnescapedHTML, mediaUrl } from '../../utils';
import MainBannerHeaderWrapper from '../MainBannerHeaderWrapper';

import './index.css';

interface IProps {
    data: ISlider
}


interface ISlider {
    id?: number,
    header?: string,
    header_position?: string,
    text?: string,
    text_position?: string,
    slider_items: Array<ILocalFile>|Array<IUrl>
}

enum ECursorDirection {
    none = 'none',
    left = 'left',
    right = 'right'
}

const cssBlock = 'sb-carousel';

const Carousel: React.FC<IProps> = ({ data ,className}) => {
    const cn = useClassnames();
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [cursorDirection, setCursorDirection] = useState({ prev: '', actual: ECursorDirection.none });
    const [cursorCoordinates, setCursorCoordinates] = useState({ coordinateX: 0, coordinateY: 0 });
    const [isAnimation, setIsAnimation] = useState<boolean>(false);
    const [swiper, setSwiper] = useState<Swiper | null>(null);

    const header = useFormattedText(data.header);

    const hoverListener = () => {
        setCursorDirection({ prev: cursorDirection.actual, actual: ECursorDirection.none });
    };

    const onClick = useCallback(() => {
        if(cursorDirection.actual === ECursorDirection.right) {
            swiper?.slideNext();
        } else if(cursorDirection.actual === ECursorDirection.left) {
            swiper?.slidePrev();
        }
    }, [cursorDirection.actual, swiper]);

    const observeCursor = useCallback((e) => {
        const containerWidth = e.target?.closest(`.${cssBlock}-container`)?.clientWidth;

        if(!containerWidth) {
            return;
        }

        const coordinateX = e.clientX;
        const coordinateY = e.clientY;
        const { top, bottom } = e.target?.closest(`.${cssBlock}-container`)?.getBoundingClientRect();

        if(coordinateY < top || coordinateY > bottom) {
            setCursorDirection({ prev: cursorDirection.actual, actual: ECursorDirection.none });

            return;
        }

        setCursorCoordinates({ coordinateX, coordinateY });

        const newCursorDirection = containerWidth / 2 >= cursorCoordinates.coordinateX ? ECursorDirection.left : ECursorDirection.right;

        if(newCursorDirection !== cursorDirection.actual) {
            setCursorDirection({ prev: cursorDirection.actual, actual: newCursorDirection });
        }
    }, [cursorDirection.actual, cursorCoordinates]);

    useEffect(() => {
        if((cursorDirection.actual === 'left' || cursorDirection.actual === 'right') && cursorDirection.prev === 'none') {
            setIsAnimation(true);
        }

        return () => {
            setIsAnimation(false);
        };
    }, [cursorDirection]);

    useEffect(() => {
        const containerEl = containerRef.current;

        if(containerEl) {
            const swiper = new Swiper(containerEl, {
                loop          : true,
                centeredSlides: true,
                slidesPerView : 1,
                spaceBetween  : 0
            });

            setSwiper(swiper);

            return () => {
                swiper.destroy(true, true);
            };
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', hoverListener);

        return () => {
            window.removeEventListener('scroll', hoverListener);
        };
    }, []);

    const elCursor = useMemo(() => {
        return (
            <div
                className={cn(`${cssBlock}__cursor`, `${cssBlock}__cursor_${cursorDirection.actual}`, { 'main-carousel__cursor_no-animation': isAnimation })}
                style={{ top: cursorCoordinates.coordinateY, left: cursorCoordinates.coordinateX }}
            >
                {cursorDirection.actual === 'right' ? <CursorRight /> : <CursorLeft />}
            </div>);
    }, [cursorDirection.actual, cursorCoordinates]);

    return (
        <div
            className={cn(`${cssBlock}-container ${className ? className : '' }`, cssBlock,)}
            ref={containerRef}
            onMouseMove={observeCursor}
            onMouseLeave={() => {
                setCursorDirection({ prev: cursorDirection.actual, actual: ECursorDirection.none });
            }}
            onClick={onClick}
        >
            <div className={cn('swiper-wrapper', `${cssBlock}__wrapper`)} key="swiper-wrapper">
                {data.slider_items.map((slide, i) => (
                    <div key={i} className={cn('swiper-slide', `${cssBlock}__slide`)}>
                        <div className={cn(`${cssBlock}__slide-container`)}>
                            <div className={cn(`${cssBlock}__img-container`)}>
                                <img
                                    className={cn(`${cssBlock}__img`)}
                                    src={slide.localFile?.url ? slide.localFile.url : mediaUrl(slide.url)}
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {elCursor}
            {header && (
                <MainBannerHeaderWrapper position={data.header_position?.replace('_', '-') as THeaderPosition} >
                    <p className={cn(`${cssBlock}__header`)}>
                        {toUnescapedHTML(header)}
                    </p>
                </MainBannerHeaderWrapper>
            )}
        </div>
    );
};

export default Carousel;
